5<template>
	<!-- 校区的咨询部 -->
	<div class=" backcolor-f">
		<div style="position: absolute;z-index: 9;top:10px;right: 10px;">
			 
			<!-- 	<el-button class="all-btn cursor" @click="duplicateQueryShow = !duplicateQueryShow" size="mini">查找重复客户</el-button>
			<el-button class="all-btn cursor" @click="circulationRecordShow = !circulationRecordShow" size="mini">客户流转记录</el-button> -->
			<el-button class="cursor" size="mini" style="margin-right: 10px;" @click="changeGrouprecord"
				v-if="listQuery.CustomerType==4">
			   客户分组记录</el-button>
			<el-button type="primary" class="cursor" style="margin-right: 10px;" @click="CreatClient" size="mini"
				v-if="['4','1','11'].indexOf(listQuery.CustomerType) !=-1">
				创建客户</el-button>
			<el-button type="primary" class="cursor" @click="importfile" size="mini"	v-if="['4','1','11','5'].indexOf(listQuery.CustomerType) !=-1">
				导入客户</el-button>
			<duplicateQuery v-if="duplicateQueryShow"></duplicateQuery>
			<circulationRecord v-if="circulationRecordShow"></circulationRecord>
			<groupRecord v-if="grouprecord" @closepop="closeGroupRecord"></groupRecord>
		</div>
		<el-tabs v-model="activeName" type="card" @tab-click="handleClicktab">
			<div class="flex justify-content-flexEnd margin-bottom-lg margin-lr-lg">
				<el-input placeholder="搜索" v-model="UserName" class="input-with-select" style="width: 400px;margin-right: 37px;">
					<el-select v-model="customerSelect" slot="prepend" style="width: 110px"
						@change="customerSelectChange">
						<el-option label="客户名称" value="1"></el-option>
					</el-select>
				</el-input>
				<!-- 客户星级下拉框 -->
			<!-- 	<span>星级：</span> -->
				<el-select v-model="listQuery.Stars" placeholder="请选择星级" >
				    <el-option
				      v-for="item in options"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value.toString()">
				    </el-option>
				  </el-select>
			</div>
			
			<el-tab-pane label="我的客户" name="1">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" border stripe>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
							<template slot-scope="scope">
								<el-link type="primary" @click="editHandle(7,scope.row.id)">{{ scope.row.UserName }}
								</el-link>
							</template>
						</el-table-column>
						<el-table-column label="工具" width="130px">
							<template class="flex justify-content-around" slot-scope="scope">

								<el-tooltip content="打电话" placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-dianhua cursor" title="打电话"
										@click="AddCall(scope.row.id)"></i>
								</el-tooltip>

								<el-tooltip content="新建任务 " placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-24px cursor" title="新建任务"
										@click="taskSubmission(scope.row.id)"></i>
								</el-tooltip>
								<el-tooltip content="创建订单" placement="bottom" effect="light" class="myClient-right-5">
									<router-link :to="{name:'addOrder',params: {detail:JSON.stringify(scope.row)}}">
										<i class="iconfont icon-folder-add cursor" title="添加订单"></i>
									</router-link>

								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommand">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{ids:scope.row.id,num:1}">添加任务</el-dropdown-item>
										<!-- <el-dropdown-item :command="{ids:scope.row.id,num:2}">发送场景短信</el-dropdown-item> -->
										<el-dropdown-item :command="{detail:scope.row,num:3}">设置标签</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:4}">添加到待拨打列表
										</el-dropdown-item>
										<el-dropdown-item
											:command="{ids:scope.row.id,num:5,progress:scope.row.Progress}">
											设置客户进展
										</el-dropdown-item>
										<el-dropdown-item
											:command="{ids:scope.row.id,num:6,groupId:scope.row.Grouping}">
											设置分组
										</el-dropdown-item>
										<el-dropdown-item
											:command="{ids:scope.row.id,num:7,userName:scope.row.UserName}">
											添加跟进任务
										</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:9}">共享客户</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:10}">放弃客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>

					</el-table>

				</div>

			</el-tab-pane>
			<el-tab-pane label="部门客户" name="4">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" border stripe>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
							<template slot-scope="scope">
								<el-link type="primary" @click="editHandle(7,scope.row.id)">{{ scope.row.UserName }}
								</el-link>
							</template>
						</el-table-column>
						<el-table-column label="工具" width="130px">
							<template class="flex justify-content-around" slot-scope="scope">

								<el-tooltip content="打电话" placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-dianhua cursor" title="打电话"
										@click="AddCall(scope.row.id)"></i>
								</el-tooltip>

								<el-tooltip content="新建任务 " placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-24px cursor" title="新建任务"
										@click="taskSubmission(scope.row.id)"></i>
								</el-tooltip>
								<el-tooltip content="创建订单" placement="bottom" effect="light" class="myClient-right-5">
									<router-link :to="{name:'addOrder',params: {detail:JSON.stringify(scope.row)}}">
										<i class="iconfont icon-folder-add cursor" title="添加订单"></i>
									</router-link>

								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommand">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{ids:scope.row.id,num:1}">添加任务</el-dropdown-item>
										<!-- <el-dropdown-item :command="{ids:scope.row.id,num:2}">发送场景短信</el-dropdown-item> -->
										<el-dropdown-item :command="{detail:scope.row,num:3}">设置标签</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:4}">添加到待拨打列表
										</el-dropdown-item>
										<el-dropdown-item
											:command="{ids:scope.row.id,num:5,progress:scope.row.Progress}">
											设置客户进展
										</el-dropdown-item>
										<el-dropdown-item
											:command="{ids:scope.row.id,num:6,groupId:scope.row.Grouping}">
											设置分组
										</el-dropdown-item>
										<el-dropdown-item
											:command="{ids:scope.row.id,num:7,userName:scope.row.UserName}">
											添加跟进任务
										</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:9}">共享客户</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:10}">放弃客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>

					</el-table>

				</div>

			</el-tab-pane>

			<el-tab-pane label="待分配客户" name="11">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" tooltip-effect="dark" border stripe
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommandmanage">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{val:scope.row}">分配客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>
			<el-tab-pane label="公司客户" name="5">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" v-loading="loading" tooltip-effect="dark" border stripe
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommandmanage">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{val:scope.row}">分配客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>


			<!-- <el-tab-pane label="团队客户" name="1" disabled></el-tab-pane>
			<el-tab-pane label="共享客户" name="2" disabled></el-tab-pane> -->
			<el-tab-pane label="客户公海" name="3">
				<div style="width: 100%;padding: 0px 20px;margin: 5px 0 ;">
					<el-table :data="CustomerList" border stripe>
						<el-table-column label="客户全名" prop="UserName" width="140" height="40">
							<template slot-scope="scope">
								<span @click="editHandle(7,scope.row.id)" class="cursor">{{ scope.row.UserName }}</span>
							</template>
						</el-table-column>
						<!-- <el-table-column label="工具" width="130px">
							<template class="flex justify-content-around" slot-scope="scope">

								<el-tooltip content="打电话" placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-dianhua cursor" title="打电话"
										@click="AddCall(scope.row.id)"></i>
								</el-tooltip>

								<el-tooltip content="新建任务 " placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-24px cursor" title="新建任务"
										@click="taskSubmission(scope.row.id)"></i>
								</el-tooltip>
								<el-tooltip content="创建订单" placement="bottom" effect="light" class="myClient-right-5">
									<i class="iconfont icon-folder-add cursor" title="添加订单"
										@click="goNav('/order/add')"></i>
								</el-tooltip>
							</template> -->
						</el-table-column>
						<el-table-column label="标签" prop="Label" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="就读学校" prop="School" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column label="年级" prop="Grade" width="100" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column label="最近动态时间" prop="RecentNewsTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.RecentNewsTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="最后跟进人" prop="FollowUserName" width="100px"> </el-table-column>
						<el-table-column label="创建客户时间" prop="AddTime" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.AddTime | dateTimeFilter}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-dropdown @command="handleCommand">
									<span class="el-dropdown-link cursor">
										操作<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="{ids:scope.row.id,num:4}">添加到待拨打列表
										</el-dropdown-item>
										<el-dropdown-item :command="{ids:scope.row.id,num:11}">认领客户</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-tab-pane>

		</el-tabs>
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="listQuery.pageSize" :page-sizes="[10,15,20,50,100,200,500]" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
		<div class="margin-top padding-lr-lg">


		</div>

		<customerDetails :Visible="editVisible && editType === 7" :hasEdit="activeName==4 || activeName==1" :message="ids" @closepop="closeEditPop">
		</customerDetails>

		<customersProgress :Visible="editVisible && editType === 2" :customersID="progressId" @closepop="closeEditPop">
		</customersProgress>
		<customersGroup :Visible="editVisible && editType === 3" :customersID="All_id" @closepop="closeEditPop">
		</customersGroup>
		<followUpRecord :Visible="editVisible && editType === 4" :customers="User" @closepop="closeEditPop">
		</followUpRecord>

		<el-dialog title="客户导入" :visible.sync="showimport" @close="getCustomerList" width="550px">
			<!-- :action="baseURL+'/api/Customer/Import?type='+importid" multiple -->

			<el-upload class="upload-demo" style="padding:40px 0px;text-align:center" drag
			:action="'/antis-service/api/Customer/Import?type='+listQuery.CustomerType+'&isadd='+listQuery.isadd"
			:headers="{Authorization:token}"
				:on-success="up_success">
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				<div class="el-upload__tip" slot="tip">只能上传excel文件,单次最多上传1000个信息 点击 <a style="color:#2FA4E7"
						target="_blank" :href="baseURL+'/temp/客户导入模板.xlsx'">下载导入模板</a>
 
				</div>
			</el-upload>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getToken
	} from '@/utils/auth'
	import AsList from '@/components/as-list'
	import duplicateQuery from './components/duplicateQuery.vue'
	import circulationRecord from './components/circulationRecord.vue'
	import groupRecord from './components/groupRecord.vue'
	import customerProfile from '@/components/add-plans/customerProfile.vue'
	import customerDetails from './components/customerDetails.vue'

	import customersProgress from '@/components/customers-actions/customers-progress.vue'
	import customersGroup from '@/components/customers-actions/customer-group.vue'
	import followUpRecord from '@/components/customers-actions/follow-up-Record.vue'

	import baseURL from '@/utils/config'
	export default {
		name: 'myClient',
		components: {
			AsList,
			duplicateQuery,
			circulationRecord,
			groupRecord,
			customerProfile,
			customerDetails,
			customersProgress,
			customersGroup,
			followUpRecord
		},
		data() {
			return {
				// 客户星级数据
				 options: [{
				          value: 1,
				          label: '一星'
				        }, {
				          value: 2,
				          label: '二星'
				        }, {
				          value: 3,
				          label: '三星'
				        }, {
				          value: 4,
				          label: '四星'
				        }, {
				          value: 5,
				          label: '五星'
				        }],
				value: '',
				token: getToken(),
				baseURL,
				importid: 1,
				showimport: false,
				grouprecord: false,
				loading: true,
				show: false,
				isShows: false,
				duplicateQueryShow: false,
				circulationRecordShow: false,
				num: 0,
				UserName: '',
				customerSelect: "1",
				total: 0,
				editType: 1,
				ids: 0,
				All_id: {
					groupId: 0,
					ids: 0,
				},
				progressId: {
					ids: 0,
					progress: 0,
				},
				User: {
					ids: 0,
					UserName: '',
				},
				activeName: '1',
				CustomerList: [],
				listQuery: {
					CustomerType: 4,
					Stars:'',
					pageIndex: 1,
					pageSize: 15,
					isadd: true,
					Sort: [{
						Field: "id",
						Type: 1
					}]
				},
			}
		},
		watch: {
			UserName(val) {
				this.listQuery.KeyWord = val
				this.getCustomerList()
			},
			//获取星级筛选列表
			'listQuery.Stars'(){
				this.getCustomerList();
			},
		},
		created() {
			this.getCustomerList()
		},
		methods: {
			
			handleSelectionChange(val) {
				let ids=[]
				val.forEach(item=>{
					ids.push(item.id)
				})
				this.distributionCustomerids=ids.join(',')
				console.log(this.distributionCustomerids);
			},
			up_success(data) { 
				if (data.success) {
					this.$alert('导入完成 '+data.message, '导入提示', {
						confirmButtonText: '确定',
						callback: action => {
							 
						}
					});
				}
				else {
					this.$message.error("导入失败")

				}
			},
			importfile() {
				console.log(baseURL);
				this.showimport = true;
			},
			customerSelectChange(val) {
				this.num = val
			},

			goNav(url) {
				this.$router.push(url)
			},
			pageSizeChange(val) {
				this.listQuery.PageIndex = 1;
				this.listQuery.pageSize = val;
				this.getCustomerList();
			},
			pageIndexChange(val) {
				this.listQuery.PageIndex = val;
				this.getCustomerList();
			},
			getCustomerList() {
				this.API.selectCustomerList(this.listQuery).then(res => {
					this.loading = false
					this.CustomerList = res.data.rows
					this.total = res.data.total
				})
			},
			CreatClient() {
				this.$router.push('/myClient/createClient')
			},
			handleClicktab(tab, event) {
				this.listQuery.IsNotAllocated = false;
				// if (this.activeName == "1" || this.activeName == "4") this.importid = this.activeName;
			
				if(this.activeName == "11" || this.activeName == "5") 	this.listQuery.IsNotAllocated = true;
				if (this.activeName == '1' || this.activeName == '11') {
					this.activeName = '4'
					this.listQuery.isadd = true
				} 
				else {
					this.listQuery.isadd = false
				}
				this.UserName = ''
				this.listQuery.CustomerType = this.activeName
				this.listQuery.PageIndex = 1
				this.getCustomerList();
			},
			prevClick(val) {
				this.listQuery.PageIndex = val
				this.getCustomerList()
			},
			AddCall(id) {
				this.$confirm('确定将该用户加入到待拨打电话界面?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.API.callAddCallList(id).then(res => {
						if (res.code == -1) {
							this.$message.eval(res.message)
						} else if (res.code == 200) {
							this.$message.success("成功加入待拨打列表")
						}
					})
				}).catch(() => {

				});
			},
			editHandle(type, ids) {
				this.editVisible = true;
				this.editType = type;
				this.ids = ids
			},
			closeEditPop() {
				this.editVisible = false;
				this.getCustomerList()
				this.CustomerList.pop(this.CustomerList.push())
			},

			handleClick(row) { },

			taskSubmission(ids) {
				this.$Dialog.TaskSubmission({
					CutomerId: ids
				})
			},
			//设置客户标签
			chooselable(Userid, message) {
				this.$Dialog.ChooseCustomerlabel({
					'Userid': Userid,
					'num': 2,
					message: message
				})
			},
			changeGrouprecord() {
				this.grouprecord = true
			},
			closeGroupRecord() {
				this.grouprecord = false
			},
			handleCommandmanage(command) {
				this.$Dialog.ChooseStaff({Customerids:command.val.id}).then(res=>{
					this.getCustomerList()
				})
			},
			handleCommand(command) {
				if (command.num == 1) {
					this.taskSubmission(command.ids)
				}
				if (command.num == 3) {
					let list = command.detail.Label.split(',')
					console.log(command.detail)
					this.chooselable(command.detail.id, list)
				}
				if (command.num == 4) {
					this.AddCall(command.ids)
				}
				if (command.num == 5) {
					this.editVisible = true;
					this.editType = 2;
					this.progressId.ids = command.ids
					this.progressId.progress = command.progress
					this.getCustomerList()
				}
				if (command.num == 6) {
					this.editVisible = true;
					this.editType = 3;
					this.All_id.ids = command.ids
					this.All_id.groupId = command.groupId
					this.getCustomerList()
				}
				if (command.num == 7) {
					this.editVisible = true;
					this.editType = 4;
					this.User.ids = command.ids
					this.User.UserName = command.userName
					this.getCustomerList()
				}
				if (command.num == 9) {
					this.$Dialog.sharedClient({
						CustomerUserId: command.ids
					})
				}
				if (command.num == 10) {
					this.$confirm('确定放弃您的该客户?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let list = {}
						list.CustomerUserId = command.ids
						this.API.customerGiveUpCustomer(list).then(res => {
							if (res.code == -1) {
								this.$message.eval(res.message)
							} else if (res.code == 200) {
								this.$message.success("放弃成功")
								this.getCustomerList()
							}
						})
					}).catch(() => {

					});
				}
				if (command.num == 11) {
					this.$confirm('确定从公司客户公海认领该客户?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.API.customerClaimCustomer(command.ids).then(res => {
							if (res.code == -1) {
								this.$message.eval(res.message)
							} else if (res.code == 200) {
								this.$message.success("成功认领该客户")
								this.getCustomerList()
							}
						})
					}).catch(() => {

					});
				}
			}
		}
	}
</script>

<style scoped>
	>>>.el-table {
		box-shadow: 0 0 8px #cdcdcd;
	}

	>>>.el-table th {
		background-color: #2FA4E7;
		color: #fff;
	}

	>>>.el-tabs__item:hover {
		color: #2FA4E7;
	}

	.myClient-right-5 {
		color: #2FA4E7;
		font-weight: bold;
		font-size: 16px;
		margin-right: 10px;
	}

	>>>.is-active {
		height: 43px;
		font-size: 17px;
		font-weight: bold !important;
	}
</style>